import React from 'react';
import ApexCharts from 'react-apexcharts';

export default function ChartBarStacked({lines, data, colors, config}) {
	let options = {
		xaxis: {
			categories: lines,
			lines: {
				show: false,
			},
			labels: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		colors: colors,
		chart: {
			type: 'bar',
			stacked: true,
			toolbar: {show: false},
			fontFamily: 'Cabin, Roboto, sans-serif',
		},
		plotOptions: {
			bar: {
				horizontal: true,
				dataLabels: {
					total: {
						enabled: true,
						offsetX: 5,
						offsetY: 2,
						style: {
							fontSize: '1.4rem',
						},
						formatter: (val) => {
							return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
						},
					},
				},
			},
		},
		dataLabels: {
			enabled: true,
			formatter: (val) => {
				return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
			},
		},
		stroke: {
			width: 0,
			colors: ['#ffffff'],
		},
		title: {
			text: '',
		},
		yaxis: {
			show: true,
			labels: {
				show: true,
				align: 'left',
				style: {
					colors: [],
					fontSize: '1.4rem',
				},
				offsetX: -20,
				offsetY: 0,
				rotate: 0,
			},
		},
		grid: {show: false},
		fill: {
			opacity: 1,
		},
		legend: {
			position: 'top',
			horizontalAlign: 'center',
			offsetX: 0,
			floating: true,
			fontSize: '14px',
		},
		tooltip: {
			y: {
				formatter: (val) => {
					return val.toFixed(config?.decimals || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
				},
			},
		},
	};

	return <ApexCharts series={data} options={options} type="bar" width="100%" height="95%" />;
}
