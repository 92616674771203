import React, {useEffect, useState, useCallback} from 'react';
import utils from '../../utils';
import useAction from '../../store/actions';
import ActionButtons from '../ActionButtons/ActionButtons';
import SearchBox from '../SearchBox/SearchBox';
import AppError from '../../pages/Error/AppError';
import ListViewRow from './ListViewRow';
import ListViewHeader from './ListViewHeader';
import ButtonBasic from '../Buttons/ButtonBasic';
import FiltersSelect from './FiltersSelect';
import ListViewPagination from './ListViewPagination';
import Loader from '../Loader/Loader';
import toast from 'react-hot-toast';
import ListViewTable from './ListViewTable';

export default function ListView({
	globalModule,
	prefix,
	popupSelectCallback,
	disabledRecords,
	hiddenRecords,
	userData,
	disableMultiple,
	config,
	additionalData,
	fromModule,
}) {
	const [rows, setRows] = useState([]);
	const [checked, setChecked] = useState(false);
	const [offset, setOffset] = useState(0);
	const [search, setSearch] = useState('');
	const [error, setError] = useState(false);
	const [dataSort, setDataSort] = useState({});
	const [module, setModule] = useState('');
	const [filters, setFilters] = useState(null);
	const [savedFilters, setSavedFilters] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(0);
	const [popupLoad, setPopupLoad] = useState(false);

	const sAction = useAction();
	const viewData = sAction.dsGet(`${prefix}`);
	const isPopup = prefix !== 'view' ? true : false;
	const listSelected = sAction.dsGet(`${prefix}/list/selected`);
	const numberOfRecords = sAction.dsGet('config/systemSettings/numberOfRecords');

	useEffect(() => {
		setChecked(false);
		setOffset(0);
		setDataSort({});
		setFilters(null);
		setSavedFilters([]);
		setSearch('');
		setModule(globalModule);
		if (isPopup) {
			setModule(viewData?.module);
		}
	}, [globalModule]);

	useEffect(() => {
		if (module) {
			getList({});
		}
	}, [module]);

	useEffect(() => {
		if ((offset > 0 || search || !search) && module) {
			getList(dataSort, true);
		}
	}, [offset, search]);

	useEffect(() => {
		if (filters !== null) {
			setOffset(0);
			getList(dataSort);
		}
	}, [filters]);

	useEffect(() => {
		setRows(viewData?.list?.data || []);
		if (viewData?.list?.data?.length === 0 || !viewData?.list?.data?.[0]?.checked) {
			setChecked(false);
		}
		let checkedItemsOnCurrentList = [];
		viewData?.list?.data?.forEach((element) => {
			if (listSelected.includes(element.id)) {
				checkedItemsOnCurrentList.push(element.id);
			}
		});

		setChecked(checkedItemsOnCurrentList.length === numberOfRecords);
	}, [viewData?.list?.data]);

	const labelSave = utils.translate('LBL_NOTHING_TO_SAVE');
	const saveFilters = () => {
		if (Object.keys(filters).length === 0) {
			return toast.success(labelSave);
		}

		const filter = savedFilters.find(obj => obj?.id === selectedFilter);
		const indexOfFilter = savedFilters.findIndex(obj => obj?.id === filter?.id);

		sAction.openPopup('SaveFilterPopup', 'LBL_SAVE_FILTER', true, { filters, module, id: selectedFilter, name: filter?.name || '' },
			(data) => {
				const newFilter = { id: data.id, name: data.name, filters };
				let newFilters = [...savedFilters];

				if (indexOfFilter !== -1) {
					newFilters[indexOfFilter] = newFilter;
				} else {
					newFilters = [...savedFilters, newFilter];
				}

				setSavedFilters(newFilters);
			});
	};

	const onChangeSearch = (value) => {
		setSearch(value);
		setOffset(0);
	};

	const onChangeSort = (sortValues) => {
		setDataSort(sortValues);
		setOffset(0);
		getList(sortValues);
	};

	const selectAllRecords = () => {
		setChecked(!checked);
		let checkedRecords = listSelected || [];
		if (!checked) {
			viewData?.list?.data?.forEach((element) => {
				if (!disabledRecords?.includes(element.id) && !checkedRecords?.includes(element.id)) {
					checkedRecords.push(element.id);
				}
			});
		} else {
			viewData?.list?.data?.forEach((element) => {
				const index = checkedRecords.indexOf(element.id);
				if (index !== -1) {
					checkedRecords.splice(index, 1);
				}
			});
		}

		sAction.dsSet(`${prefix}/list/selected`, checkedRecords);
	};

	const getList = (sortValues, preserveSelectedRows = false) => {
		if (!isPopup) {
			sAction.contentLoad();
		} else {
			setPopupLoad(true);
		}

		utils
			.post('getList', {module, search, offset, sort: sortValues, filters, additionalData: additionalData, fromModule})
			.then((res) => {
				if (res.dataResult !== 'No Access' && res.data) {
					setError(false);
					if (res.data.dataSort?.length === 0 || dataSort === 0) {
						setDataSort({});
					} else {
						setDataSort(res.data.dataSort);
					}
					if (offset !== 0) {
						sAction.dsSet(`${prefix}/list/data`, res.data.data);
						setRows(res.data.data);
					}
					let setRecords = res.data.data;
					// if (disabledRecords?.length > 0) {
					// 	setRecords = res.data.data.filter(record => !disabledRecords?.some(id => id == record.id));
					// }
					let selectedRecordsInit = [];
					if (preserveSelectedRows) {
						selectedRecordsInit = listSelected;
					}
					setTimeout(() => {
						sAction.initList(
							res.data.header,
							setRecords,
							res.data.buttons,
							res.data.dataSort,
							res.data.timeline,
							prefix,
							listSelected
						);
						setSavedFilters(null);
						setSavedFilters(res.data.filters);
					}, 75);
				} else {
					setError(true);
				}
				if (!isPopup) {
					sAction.contentUnload();
				}
				setPopupLoad(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const noAccessMessage = utils.translate('LBL_NO_ACCESS');
	const noAccessInfo = utils.translate('LBL_NO_ACCESS_MESSAGE');
	const globalTranslates = sAction.dsGet('config/translate/label');

	if (error) {
		return <AppError errorMessage={noAccessMessage} infoMessage={noAccessInfo} icon="warning" />;
	}

	return (
		<>
			<div className="actionBox">
				{!isPopup ?
					<ActionButtons load={config.contentLoad} userData={userData} prefix={prefix} viewData={viewData} buttons={viewData.list.buttons} /> :
					listSelected?.length > 0 ?
						<ActionButtons>
							<ButtonBasic
								icon={'checked'}
								onClick={() => popupSelectCallback(viewData?.list?.selected)}
								style={'green'}
								label={'LBL_CONFIRM'}>
								<span> ({listSelected?.length})</span>
							</ButtonBasic>
						</ActionButtons>
						: <div className="actionButtons"></div>
				}
				<FiltersSelect
					savedFilters={savedFilters}
					selectedFilter={selectedFilter}
					setSelectedFilter={setSelectedFilter}
					setFilters={setFilters}
					setSavedFilters={setSavedFilters}
				/>
				<SearchBox searchTimeout={true} module={module} onChangeSearch={(e) => onChangeSearch(e)} />
			</div>
			<div className={`listView ${isPopup ? 'listView__inPopup' : ''}`}>
				{popupLoad ? <div className="loadBoxListViewPopup"><Loader /></div> :
					<div id="listViewTable" className="listView__table">
						<table className="listView__table-box" cellPadding="0" cellSpacing="0">
							<thead>
								<ListViewHeader
									setSort={onChangeSort}
									globalTranslates={globalTranslates}
									sort={dataSort}
									headerColumns={viewData?.list?.header}
									selectAllRecords={selectAllRecords}
									checked={checked}
									setFilters={setFilters}
									filters={filters}
									prefix={prefix}
									saveFilters={saveFilters}
									module={module}
									disableMultiple={disableMultiple}
								/>
							</thead>
							<ListViewTable
								viewData={viewData}
								popupSelectCallback={popupSelectCallback}
								prefix={prefix}
								disableMultiple={disableMultiple}
								rows={rows}
								disabledRecords={disabledRecords}
								hiddenRecords={hiddenRecords}
							/>
						</table>
					</div>
				}
				<ListViewPagination
					numberOfRecords={numberOfRecords}
					rows={rows}
					setOffset={setOffset}
					offset={offset}
				/>
			</div>
		</>
	);
}
