export default function roundNumber(num) {
	let number = 0;
	if (typeof num !== 'string' && typeof num !== 'boolean' && num) {
		if (Number.isInteger(num)) {
			number =  num.toFixed(2).replace(/\.00$/, ',00');
		} else {
			number = num.toFixed(2);
		}
	} else {
		return num;
	}

	return number.replace(',', '.');
}