import utils from '.';

export default function getModuleName() {
	const pathname = window.location.pathname.split('/');
	let module = pathname.includes('list') || pathname.includes('import') ? pathname.pop() : pathname.reverse()[1];
	module = pathname.includes('detail') ? pathname[0] : module;
	module = pathname.includes('detail') && pathname.length >= 5 ? pathname[1] : module;
	if (!module || module === utils.getUrlPrefix()) {
		module = pathname[0];
	}

	return module;
}