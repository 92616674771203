export default function checkForErrorsInFields(fields) {
	const detailFields = fields;
	const fieldKeys = Object.keys(detailFields);
	let error = false;
	fieldKeys.forEach((key) => {
		if (detailFields[key].error) {
			error = true;
		}
	});
	return error;
}
