import utils from '..';

export default function validateRequiredFields(fields) {
	const detailFields = fields;
	const fieldKeys = Object.keys(detailFields);
	let requiredFieldsFilled = true;
	fieldKeys.forEach((key) => {
		if (
			(!detailFields[key].value ||
				(detailFields[key].value === '0000-00-00' && detailFields[key].fieldType === 'date') ||
				(detailFields[key].value === '0000-00-00 00:00:00' && detailFields[key].fieldType === 'dateTime') ||
				(detailFields[key].value === '00:00:00' && detailFields[key].fieldType === 'dateTime') ||
                (!utils.validateEmail(detailFields[key].value) && detailFields[key].fieldType === 'email') ||
				(detailFields[key].value === 0 && detailFields[key].fieldType === 'select')) &&
				detailFields[key].fieldType !== 'checkbox' &&
				detailFields[key].required) {
			requiredFieldsFilled = false;
		}
	});
	return requiredFieldsFilled;
}
