import React from 'react';
import utils from '../../utils';

export default function ButtonReport({
	label,
	icon,
	iconColor,
	onClick,
}) {
	const translatedLabel = utils.translate(label);

	return (
		<button onClick={(e) => onClick ? onClick(e) : null} className="buttonReport">
			<span style={{color: iconColor}} className={`icon-${icon} iconfa-${icon} ${label ? 'buttonReport__withLabel' : ''}`} />
			{label ? translatedLabel : null}
		</button>
	);
}