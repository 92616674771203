import React, {useState, useEffect} from 'react';
import utils from '../../utils';
import {TextField, Button} from '@mui/material';
import {Link} from 'react-router-dom';

export default function Restore() {
	const [checkCode, setcheckCode] = useState(0);
	const [email, setEmail] = useState('');
	const [result, setResult] = useState(0);
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [error, setError] = useState({password: '', password2: ''});

	var res_code = window.location.search.split('=')[1];

	useEffect(() => {
		if (res_code) {
			getValidRestoreCode();
		}
	}, []);

	const getValidRestoreCode = () => {
		utils
			.post('restoreValidCode', {res_code}, false)
			.then((res) => {
				if (res['result'] === 1) {
					setcheckCode(1);
					setEmail(res['resultEmail']);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const localError = utils.validateRestorePassword(password, password2);

		if (localError.password !== '' || localError.password2 !== '') {
			return setError(localError);
		}

		setError({password: '', password2: ''});

		utils
			.post('restore', {email, password, password2})
			.then((res) => {
				if (res['result'] === 1) {
					setResult(1);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="login">
			<div className="login__container">
				{checkCode === 1 ? (
					<>
						{result === 1 ? (
							<>
								<div className="login__label">Your passwod was changed.</div>
								<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
									Back to Login
								</Link>
							</>
						) : (
							<>
								<div className="login__label">Enter new password</div>

								<form className="login__form" onSubmit={handleSubmit} id="restoreForm">
									<div className="login__inputBox">
										<TextField className={`login__form-input ${error.password ? 'login__form-input--error' : ''}`} label="Password" variant="outlined" type="password" onChange={(e) => setPassword(e.target.value)} value={password} helperText={error.password} error={!!error.password} />
									</div>
									<div className="login__inputBox">
										<TextField className={`login__form-input ${error.password2 ? 'login__form-input--error' : ''}`} label="Confirm Password" variant="outlined" type="password" onChange={(e) => setPassword2(e.target.value)} value={password2} helperText={error.password2} error={!!error.password2} />
									</div>
									<Button className="login__form-button" variant="contained" type="submit">
										Save
									</Button>
								</form>
								<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
									Back to Login
								</Link>
							</>
						)}
					</>
				) : (
					<>
						<div className="login__label">Entered invalide link.</div>
						<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
							Back to Login
						</Link>
					</>
				)}
			</div>
		</div>
	);
}
