export default function convertTime(time, fromUnit, toUnit) {
	const unitsInSeconds = {
		seconds: 1,
		minutes: 60,
		hours: 3600,
		days: 86400,
		weeks: 604800,
		months: 2628000,
		years: 31536000,
	};

	let timeInSeconds = time * unitsInSeconds[fromUnit.toLowerCase()];
	let convertedTime = timeInSeconds / unitsInSeconds[toUnit.toLowerCase()];

	return convertedTime;
}
