export default function formatDateToDB(date) {
	if (!date) {
	  return '';
	}

	const today = new Date(date);
	const getYear = today.getFullYear();
	const getMonth = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
	const getDay = today.getDate().toString().padStart(2, '0');
	const getHours = today.getHours().toString().padStart(2, '0');
	const getMinutes = today.getMinutes().toString().padStart(2, '0');
	const getSeconds = today.getSeconds().toString().padStart(2, '0');

	const getFormattedDate = `${getDay}.${getMonth}.${getYear} ${getHours}:${getMinutes}:${getSeconds}`;

	const dateArray = getFormattedDate.split(' ');
	const dateString = dateArray[0];
	const timeString = dateArray[1];

	const dateArrayFormatted = dateString.split('.');
	const day = dateArrayFormatted[0];
	const month = dateArrayFormatted[1];
	const year = dateArrayFormatted[2];
	const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${timeString}`;

	return formattedDate;
}