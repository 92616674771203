export default function formatDate(date) {
	// If date is null or undefined, use the current date
	if (!date) {
		date = new Date();
	} else {
		// Convert the string date to a Date object
		const dateTimeArray = date.split(' ');
		const dateArray = dateTimeArray[0].split('-');
		date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], ...dateTimeArray[1]?.split(':') || []);
	}

	// Format the date into dd-mm-yyyy HH:mm:ss
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');

	return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}
