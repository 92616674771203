import React, {useState, useEffect} from 'react';
import { Tabs, Tab } from '@mui/material';
import utils from '../../utils';
import NotificationItem from './NotificationItem';
import Loader from '../Loader/Loader';
import toast from 'react-hot-toast';

export default function NotificationsList({
	setNotifyNumber,
	notifyNumber,
	closeNotifications,
}) {
	const [activeTab, setActiveTab] = useState('unread');
	const [notifications, setNotifications] = useState([]);
	const [count, setCount] = useState({unread: null, read: null, archived: null});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setActiveTab('unread');
	}, []);

	useEffect(() => {
		setLoading(true);
		utils.post('getNotifications', {module: 'notification', status: activeTab}).then((res) => {
			setNotifications(res.data.notifications);
			setCount(res.data.count);
			setLoading(false);
		}).catch((error) => {
			console.error(error);
		});
	}, [activeTab]);

	const renderCntNumber = (number, active) => {
		if (number === null) {
			return <div className={active ? 'notifications__loader' : 'notifications__loaderBlack'} />;
		}
		if (number > 99) {
			return '99+';
		}
		if (!number) {
			return 0;
		}
		return number;
	};

	const labelNoNotifications = utils.translate('LBL_NO_NOTIFICATIONS_FOUND');

	const renderNotifications = () => {
		if (notifications.length === 0) {
			return <div className="notificationsList__noItems">{labelNoNotifications}</div>;
		}
		return notifications.map((item) => {
			return <NotificationItem
				key={item.id}
				dateCreated={item.crDTs}
				createdByUsername={item.crUserName}
				message={item.message}
				title={item.title}
				type={item.type}
				status={activeTab}
				id={item.id}
				parentModule={item.parentModule}
				parentId={item.parentId}
				parentName={item.parentName}
				setNotifyNumber={setNotifyNumber}
				notifyNumber={notifyNumber}
				setCount={setCount}
				count={count}
				closeNotifications={closeNotifications}
			/>;
		});
	};

	const labelAllAsRead = utils.translate('LBL_MARK_ALL_AS_READ');
	const labelArchiveAll = utils.translate('LBL_ARCHIVE_ALL');

	const renderAllButtonLabel = () => {
		if (activeTab === 'unread') {
			return labelAllAsRead;
		} else if (activeTab === 'read') {
			return labelArchiveAll;
		}
	};

	const labelSaving = utils.translate('LBL_SAVING');
	const labelSaveSuccess = utils.translate('LBL_RECORD_WAS_SAVED');
	const labelSaveError = utils.translate('LBL_ERROR');

	const allAction = async () => {
		let toStatus = '';
		if (activeTab === 'unread') {
			toStatus = 'read';
		} else if (activeTab === 'read') {
			toStatus = 'archived';
		} else {
			return;
		}

		const resultPromise = utils.post('changeNotificationStatusAll', {module: 'notification', fromStatus: activeTab, toStatus: toStatus});

		toast.promise(resultPromise, {
			loading: labelSaving,
			success: labelSaveSuccess,
			error: labelSaveError,
		});

		try {
			const res = await resultPromise;
			if (res.data) {
				setCount({...count, [activeTab]: count[activeTab] - notifications.length, [toStatus]: count[toStatus] + notifications.length});
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<div className="notifications__allAction" onClick={() => allAction()}>{renderAllButtonLabel()}</div>
			<div className="notificationsList">
				<Tabs className="notifications__tabs" value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}
					TabIndicatorProps={{
						style: {
							backgroundColor: '#6F7AAD',
						}
					}}>
					<Tab
						iconPosition="end"
						value={'unread'}
						icon={<div className="notifications__counter">{renderCntNumber(count.unread, activeTab === 'unread')}</div>}
						className="notifications__tab" label={utils.translate('LBL_NOTIFY_UNREAD')}
					/>
					<Tab
						iconPosition="end"
						value={'read'}
						icon={<div className="notifications__counter">{renderCntNumber(count.read, activeTab === 'read')}</div>}
						className="notifications__tab"
						label={utils.translate('LBL_NOTIFY_READ')}
					/>
					<Tab
						iconPosition="end"
						value={'archived'}
						icon={<div className="notifications__counter">{renderCntNumber(count.archived, activeTab === 'archived')}</div>}
						className="notifications__tab"
						label={utils.translate('LBL_NOTIFY_ARCHIVED')}
					/>
				</Tabs>
				<div className="notificationsList__content">
					{loading ?
						<div className="loadBox"><Loader /></div> :
						renderNotifications()
					}
				</div>
			</div>
		</>
	);
}