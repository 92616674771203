import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import {default as MuiTooltip} from '@mui/material/Tooltip';
import Tooltip from '../Tooltip/Tooltip';

export default function TextArea({
	label,
	readonly,
	value,
	placeholder,
	required,
	fieldLength,
	onChange,
	error,
	type,
	fieldName,
	onBlur,
	minRows,
	maxRows,
	className,
	defaultValue,
	info,
}) {
	const [inputValue, setInputValue] = useState(value);
	const handleChangeRef = useRef((event) => {
		const newValue = event.target.value;

		if (onChange) {
			onChange(event);
		}

		setInputValue(newValue);
	});

	const debugMode = localStorage.getItem('debugMode') ? true : false;

	return (
		<div className={`inputFieldBox ${className}`}>
			{label && (
				<label className="detailField__label">
					{label}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon icon-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<TextField
				error={!!error}
				required={required}
				placeholder={placeholder}
				className={`textArea inputField ${readonly ? 'inputField__readonly' : ''} ${error ? 'inputField__error' : ''}`}
				onChange={handleChangeRef.current}
				value={inputValue || defaultValue || ''}
				name={fieldName}
				type={type}
				disabled={readonly}
				onBlur={onBlur}
				multiline
				minRows={minRows}
				maxRows={maxRows}
				inputProps={{
					readOnly: readonly,
					maxLength: fieldLength,
				}}
			/>
			{error ? (
				<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
					<span className="icon-warning inputFieldBox__error-icon"></span>
				</MuiTooltip>
			) : null}
		</div>
	);
}
