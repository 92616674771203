import React, {useState} from 'react';
import ButtonBasic from '../Buttons/ButtonBasic';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { enUS, cs, sk } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import useAction from '../../store/actions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import utils from '../../utils';

export default function CalendarChangeDate({date, setDate, calType, calendarRef}) {
	const [open, setOpen] = useState(false);
	const sAction = useAction();

	const getMonthName = (dateString) => {
		const date = new Date(dateString);
		const monthNumber = date.getMonth();
		const months = utils.getEnum('months');

		return months[monthNumber];
	};

	const setToday = () => {
		calendarRef.current.getApi().today();
		if (calType === 'month') {
			setDate(utils.getFirstDateInMonth(new Date()));
		} else if (calType === 'week') {
			setDate(utils.getMonday(new Date()));
		} else if (calType === 'day') {
			setDate(utils.formatDateToDB(new Date()));
		}
	};

	const renderDayButton = () => {
		if ((calType === 'month' && !utils.isDateInCurrentMonth(date)) ||
			(calType === 'week' && !utils.isDateInCurrentWeek(date)) ||
			(calType === 'day' && utils.formatDateToDB(new Date()).split(' ')[0] !== utils.formatDateToDB(date).split(' ')[0])) {
			return <ButtonBasic className="calendar__actionButtons-today" label={'LBL_TODAY'} onClick={() => setToday()} />;
		} else {
			return <div className="calendar__actionButtons-invisibleButton"></div>;
		}
	};

	const changeDate = (inputDate, action) => {
		const currentDate = new Date(inputDate);
		if (action === 'next') {
			calendarRef.current.getApi().next();
		} else {
			calendarRef.current.getApi().prev();
		}
		if (calType === 'month') {
			if (action === 'next') {
				currentDate.setMonth(currentDate.getMonth() + 1);
			} else if (action === 'prev') {
				currentDate.setMonth(currentDate.getMonth() - 1);
			}
		} else if (calType === 'week') {
			if (action === 'next') {
				currentDate.setDate(currentDate.getDate() + 7);
			} else if (action === 'prev') {
				currentDate.setDate(currentDate.getDate() - 7);
			}
		} else if (calType === 'day') {
			if (action === 'next') {
				currentDate.setDate(currentDate.getDate() + 1);
			} else if (action === 'prev') {
				currentDate.setDate(currentDate.getDate() - 1);
			}
		}

		calendarRef.current.getApi().gotoDate(currentDate);

		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		const dayInMonth = currentDate.getDate();
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedDayInMonth = dayInMonth < 10 ? `0${dayInMonth}` : dayInMonth;
		const formattedDate = `${year}-${formattedMonth}-${formattedDayInMonth}`;

		setDate(formattedDate);
	};

	const localeMap = {
		en: enUS,
		cz: cs,
		sk: sk,
	};
	const language = sAction.dsGet('user/language');
	const locale = localeMap[language] || enUS;

	return (
		<div className="calendar__actionButtons">
			<ButtonBasic icon="arrow-left1" onClick={() => changeDate(date, 'prev')} />
			<ButtonBasic hideLabelOnLowRes={false} className="calendar__actionButtons-dateButton" label={`${getMonthName(date)} ${date.slice(0, 4)}`} onClick={() => setOpen(true)} />
			<ButtonBasic icon="arrow-right1" onClick={() => changeDate(date, 'next')} />
			{renderDayButton()}
			<LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
				<MobileDatePicker
					views={['year', 'month']}
					label="Year and Month"
					value={isValid(parseISO(date)) ? parseISO(date) : null}
					onAccept={(date) => changeDate(date)}
					open={open}
					closeOnSelect={true}
					onClose={() => setOpen(false)}
					className="calendar__dateSelector"
				/>
			</LocalizationProvider>
		</div>
	);
}