const initialState = {};

initialState.config = {
	leftMenu: [],
	translate: {},
	load: false,
	contentLoad: false,
	path: null,
	currencies: [],
	systemSettings: {},
};
initialState.config.translate = {
	label: {},
	enum: {},
};
initialState.user = {
	id: null,
	email: null,
	username: null,
	firstName: null,
	lastName: null,
	nickame: null,
	sysadmin: 0,
	language: 'cz',
	photo: '',
	phone: '',
	dob: '',
	changes: {},
	rights: {},
};
initialState.view = {
	module: null,
	recordId: null,
	detail: {},
	list: {},
	report: {},
	predefinedFields: {},
	calendar: {
		reload: false,
	}
};
initialState.view.detail = {
	tabs:  [],
	fields: {},
	buttons: [],
	changes: {},
	subpanels: [],
	linesData: {
		def: [],
		lines: [],
	},
};
initialState.view.list = {
	header:  [],
	data: [],
	buttons: [],
	sort: [],
	selected: [],
	timeline: null,
};
initialState.toast = [];
initialState.popups = [];

initialState.config.leftMenu = [];

export default initialState;