export default function datesAreInSameDay(date1, date2) {
	const getDayStart = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

	const startOfDay1 = getDayStart(date1);
	const startOfDay2 = getDayStart(date2);

	return (
		startOfDay1.getFullYear() === startOfDay2.getFullYear() &&
    startOfDay1.getMonth() === startOfDay2.getMonth() &&
    startOfDay1.getDate() === startOfDay2.getDate()
	);
}
