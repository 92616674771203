export default {
	nav: {
		product: 'Product',
		about: 'About Us',
		partners: 'Partners',
		offerto: 'I Want Offerto!'
	},
	main: {
		header: 'Reliable Real-Time Data',
		button: 'Get Started',
	},
	about: {
		header: 'Data You Can Trust',
		firstSectionHeader: '1. Product Database',
		firstSectionText: 'Our unique product configuration solution allows you to never have to search for or calculate information manually again. With Offerto, you can set up all possible variants of your product with the necessary accessories, which you can then price in the offer with one click!',
		secondSectionHeader: '2. Price Quotes',
		secondSectionText: 'With Offerto, you get an automated tool for creating price quotes for both your salespeople and customers.',
		thirdSectionHeader: '3. Order Creation',
		thirdSectionText: 'Order with one click with direct integration into ERP systems like Pohoda, Helios, SAP, and more.',
		fourthSectionHeader: '4. Company Database',
		fourthSectionText: 'Keep all your clients, quotes, and orders neatly organized by the company you trade with.',
		fifthSectionHeader: '5. System Notifications',
		fifthSectionText: 'Offerto will always notify you of any important information in the system and your email. You will never miss anything important again!',
		sixthSectionHeader: '6. reports',
  		sixthSectionText: 'We have clear reports thanks to which you can have a perfect overview of the summary of all the data you need. And yes! We also make customized reports.',
	},
	solution: {
		header: 'Faster Business Solutions',
		text: 'Offerto helps you and your customers quickly resolve your product price, availability, and ordering with direct integration into the ERP system. Try how simple it is!',
		button: 'I Want to Try Offerto'
	},
	story: {
		header: 'About Us',
		sections: {
			story: {
				header: 'Story',
				text: 'Offerto was created from the idea of two of our salespeople. The goal was to help companies speed up and simplify their business processes with various assembly routes of any kind. After long work by our experienced programmers, software was created that can do not only that but much more, and we know that Offerto\'s story is not over yet and many other great things are being prepared for our customers.'
			},
			vision: {
				header: 'Vision',
				text: 'Our vision is to fully automate and speed up business processes. With us, your salespeople will no longer have to use Excel with a calculator in hand. Our goal is for salespeople to be able to focus on more important things than searching for prices in the catalog. When working with our clients, we primarily look at the functionality of our solution but also at its visual aspect to create a pleasant environment that you and your clients will be happy to return to.'
			},
			technology: {
				header: 'Technology',
				text: 'Offerto! Innovative software that fully adapts to your company and ensures faster communication and data exchange between colleagues and customers.'
			}
		}
	},
	reference: {
		header: 'Produced by',
		companyName: 'Innovasoft'
	},
	contact: {
		header: 'Request a Demo',
		subheader: 'Schedule a demo with our specialist',
		form: {
			name: 'First Name',
			lastName: 'Last Name',
			email: 'Email',
			phone: 'Phone',
			company: 'Company',
			position: 'Position',
			button: 'Send'
		},
		messages: {
			formError: 'Failed to send email to the contact person. Please contact us at  info@innovasoft.cz thank you.',
			formSuccess: 'Form submission was successful',
			requiredFieldsAlert: 'Please fill in all required fields!',
			validEmailAlert: 'Please enter a valid email!',
			recaptchaAlert: 'Please verify that you are not a robot!'
		}
	},
	footer: {
		email: 'E-mail:',
		phone: 'Phone:',
		copyright: 'Innovasoft',
		social: 'Social Networks'
	}
};
