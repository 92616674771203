export default function dsSet(state, action) {
	const { path, value } = action.payload;
	const properties = path.split('/');
	let newState = { ...state }; // Shallow clone of the state

	let current = newState;
	for (let i = 0; i < properties.length - 1; i++) {
		const property = properties[i];
		if (Array.isArray(current[property])) {
			const index = parseInt(properties[i + 1]);
			if (!isNaN(index)) {
				current[property] = [...current[property]]; // Shallow clone the array
				current = current[property];
				continue;
			}
		}
		current[property] = { ...current[property] }; // Shallow clone the current level
		current = current[property];
	}

	const lastIndex = properties.length - 1;
	const lastProperty = properties[lastIndex];
	const secondLastProperty = properties[lastIndex - 1];

	if (Array.isArray(current[secondLastProperty])) {
		const index = parseInt(lastProperty);
		if (!isNaN(index)) {
			current[secondLastProperty] = current[secondLastProperty].map((item, idx) =>
				idx === index ? { ...item, [value.key]: value.value } : item
			);
		}
	} else {
		current[lastProperty] = value;
	}

	return newState;
}

// Old backup

// export default function dsSet(state, action) {
// 	const { path, value } = action.payload;
// 	const properties = path.split('/');
// 	const newState = { ...state };
// 	let current = newState;
// 	for (let i = 0; i < properties.length - 1; i++) {
// 		const property = properties[i];
// 		current = current[property];
// 	}
// 	current[properties[properties.length - 1]] = value;
// 	return newState;
// }
