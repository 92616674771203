import React, {useState} from 'react';
import utils from '../../utils';
import {TextField, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState({username: '', password: ''});

	const handleSubmit = (e) => {
		e.preventDefault();
		let localError = {username: '', password: ''};
		if (!username || !password) {
			// Fill with empty string to don't display any text but mark as error
			if (!username) {
				localError.username = ' ';
			}
			if (!password) {
				localError.password = ' ';
			}
			return setError(localError);
		}
		setError({username: '', password: ''});
		utils
			.post('login', {username, password}, false)
			.then((res) => {
				if (res.usrId) {
					Cookies.set('offerto' + '_' + utils.getUrlPrefix(), res.token, {expires: 7});
					utils.hardRedirect('/');
				} else {
					setError({username: false, password: res.resultTxt});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="login">
			<div className="login__box">
				<img className="login__box-logo" src="/images/logo2_light.png" alt="App logo" />
				<h1 className="login__box-header">Welcome</h1>
				<div className="login__box-text login__box-text--first">Login to</div>
				<div className="login__box-text">continue access</div>
				<div className="login__box-linkBox">
					<a className="login__box-link" href="https://www.offerto.tech" rel="noopener noreferrer">
  						www.offerto.tech
					</a>
				</div>
			</div>
			<div className="login__container">
				<h4 className="login__header">Login</h4>
				<form className="login__form" onSubmit={handleSubmit} id="loginForm">
					<div className="login__inputBox">
						<TextField className={`login__form-input ${error.username ? 'login__form-input--error' : ''}`} label="Username" variant="outlined" onChange={(e) => setUsername(e.target.value)} value={username} helperText={error.username} error={!!error.username} />
					</div>
					<div className="login__inputBox">
						<TextField className={`login__form-input ${error.password ? 'login__form-input--error' : ''}`} label="Password" variant="outlined" type="password" onChange={(e) => setPassword(e.target.value)} value={password} helperText={error.password} error={!!error.password} />
					</div>
					<Button className="login__form-button" variant="contained" type="submit">
						Login
					</Button>
				</form>
				<Link to={`../${utils.getUrlPrefix()}/forget`} className="login__link">
					Did you forget your password?
				</Link>
			</div>
		</div>
	);
}
