export default function getFirstDateInMonth(fullDate) {
	let date = new Date(fullDate);
	if (!fullDate) {
		date = new Date();
	}
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const formattedMonth = month < 10 ? `0${month}` : month;
	const formattedDay = '01';
	const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

	return formattedDate;
}