import utils from '../../utils';

export default function print(module, recordId, buttonName) {

	return () => {
		utils
			.post('print', {module: module, recordId: recordId, name: buttonName})
			.then((res) => {
				if (res.data) {
					window.open(res.data, '_blank');
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
}
