import React, { useState } from 'react';
import utils from '../../../utils';
import useAction from '../../../store/actions';

export default function ListViewFileLink({ path, className, recordId, module, recordName, style }) {
	const fileType = path?.split('.')[1];
	const sAction = useAction();

	const download = () => {
		utils.downloadFile(module, recordId, recordName, fileType, sAction);
	};

	return (
		<td className={`${className} listViewFile`} style={style}>
			<div className="listViewFile__box">
				<span>
					<a onClick={() => download()} className="listViewFile__link">
						<span className={`icon-${fileType}`} />
						<span className="listViewFile__name">
							{`.${fileType}`}
						</span>
					</a>
				</span>
			</div>
		</td>
	);
}