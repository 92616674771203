export default function openPopup(state, action) {
	let newItem = {};
	const basicPopupData = {
		name: action.payload.name,
		header: action.payload.header,
		closeOnOutsideClick: action.payload.closeOnOutsideClick,
		callback: action.payload.callback,
		data: action.payload.data,
		id: (Math.random() * 1000000),
	};

	if (action.payload.name === 'DetailFormPopup') {
		newItem = {
			...basicPopupData,
			module: action.payload.data.module,
			recordId: action.payload.data.recordId,
		};
		newItem.detail = {
			buttons: [],
			tabs: [],
			fields: {},
			changes: {},
		};
	} else if (action.payload.name === 'ListViewPopup') {
		newItem = {
			...basicPopupData,
			module: action.payload.data.module,
		};
		newItem.list = {
			buttons: [],
			data: [],
			header: {},
			sort: {},
			timeline: {}
		};
	} else {
		newItem = {
			...basicPopupData,
		};
	}

	return {...state, popups: [...state.popups, newItem]};
}
