import useAction from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import utils from '../../utils';

export default function newRecord(module) {
	const sAction = useAction();
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	return () => {
		sAction.setPredefinedFields({});
		navigate(`/${urlPrefix}/detail/${module}`);
		sAction.dsSet('view/recordId', null);
	};
}
