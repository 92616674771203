export default function changeLanguage(state, action) {
	return { ...state, config: {
		...state.config,
		translate: {
			...state.config.translate,
			label: action.payload.label,
			enum: action.payload.enum,
		}
	}
	};
}