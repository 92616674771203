import React from 'react';
import Input from '../../FormElements/Input';
import Select from '../../FormElements/Select';
import Switch from '../../FormElements/Switch';
import utils from '../../../utils';

export default function System({setValue, state}) {
	const colors = window.colorPalette;
	const currencyOptions = utils.getEnum('currency');

	return (
		<div className="config__form">
			<div style={{borderBottom: `2px solid ${colors[0]}`}} className="config__form-section">
				<span className="iconfa-gears"/>
				{utils.translate('LBL_COMMON_SETTINGS')}
			</div>
			<Input
				label={utils.translate('LBL_NUMBER_OF_RECORDS')}
				value={state.numberOfRecords}
				onChange={(e) => setValue('numberOfRecords', e.target.value)}
				type="number"
				info={'LBL_NUMBER_OF_RECORDS_INFO'}
			/>
			<Select
				label={utils.translate('LBL_DEFAULT_CURRENCY')}
				value={state.currency}
				onChange={(e) => setValue('currency', e.target.value)}
				options={currencyOptions}
				info={'LBL_DEFAULT_CURRENCY_INFO'}
			/>
			<div style={{borderBottom: `2px solid ${colors[1]}`}} className="config__form-section">
				<span className="iconfas-modules"/>
				{utils.translate('LBL_MODULE_SETTINGS')}
			</div>
			<Input
				label={utils.translate('LBL_DELIVERY_DAYS')}
				value={state.deliveryDays}
				onChange={(e) => setValue('deliveryDays', e.target.value)}
			/>
			<Switch
				onChange={(e) => setValue('showRelatedProducts', e.target.checked ? 1 : 0)}
				checked={!!state.showRelatedProducts}
				label={utils.translate('LBL_SHOW_RELATED_PRODUCTS')}
			/>
		</div>
	);
}
