import { useSelector } from 'react-redux';

/**
 * Get enum
 * @param {string} enumName - searched enum
 * @returns {object}
 */
export default function useGetEnum(enumName){
	const translates = useSelector(state => state.config.translate.enum);
	return translates[enumName] || enumName;
}