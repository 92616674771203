import React, { useState, useEffect, useRef } from 'react';
import useAction from '../../store/actions';

export default function Popup({ popupData, index, prefix, userData }) {
	const { name, header, callback, message, data, cancelCallback, closeOnOutsideClick } = popupData;
	const sAction = useAction();
	const [PopupComponent, setPopupComponent] = useState(null);
	const [isVisible, setIsVisible] = useState(true);
	const popupRef = useRef(null);

	useEffect(() => {
		import(`./${name}`).then((popup) => {
			setPopupComponent(() => popup?.default);
		});
	}, [name]);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target) && closeOnOutsideClick) {
				closePopup();
			}
		};

		// Needs to be timed out because on second call of popup it will handle outside click even if the popup is open yet
		setTimeout(() => document.addEventListener('click', handleOutsideClick));

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	const closePopup = () => {
		setIsVisible(false);
		sAction.closePopup();
	};

	if (!isVisible || !PopupComponent) {
		return null;
	}

	return (
		<>
			{index === 0 ? <div className="popup__overlay"></div> : null}
			<div className="popup" ref={popupRef}>
				<div className="popup__content">
					<PopupComponent
						popupData={popupData}
						data={data}
						closePopup={closePopup}
						header={header}
						callback={callback}
						cancelCallback={cancelCallback}
						message={message}
						prefix={prefix}
						userData={userData}
					/>
				</div>
			</div>
		</>
	);
}