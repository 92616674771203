import React from 'react';

export default function HamburgerMenu({
	scrollToAbout,
	scrollToPartners,
	setOpenMenu,
	scrollToProduct,
	scrollToForm,
	selectLanguage,
	lang,
	locale,
}) {
	return (
		<div className="hamburgerMenu">
			<nav>
				<a href="#" onClick={(e) => {
					scrollToProduct(e);
					setOpenMenu(false);
				}}>
					{locale.nav.product}
				</a>
				<a href="#" onClick={(e) => {
					scrollToAbout(e);
					setOpenMenu(false);
				}}>
					{locale.nav.about}
				</a>
				<a href="#" onClick={(e) => {
					scrollToPartners(e);
					setOpenMenu(false);
				}}>
					{locale.nav.partners}
				</a>
				<a href="#" onClick={(e) => {
					scrollToForm(e);
					setOpenMenu(false);
				}}>
					{locale.nav.offerto}
				</a>
			</nav>
			<div onClick={() => setOpenMenu(false)} className="hamburgerMenu__close icon-cross"/>
			<div className="hamburgerMenu__langs">
				<div className={`mainPage__langs-item ${lang === 'cz' ? 'active' : ''}`} onClick={() => selectLanguage('cz')}>CZ</div>
				<div className="hamburgerMenu__divider" />
				<div className={`mainPage__langs-item ${lang === 'en' ? 'active' : ''}`} onClick={() => selectLanguage('en')}>EN</div>
			</div>
		</div>
	);
}