export default function dsGet(state, action) {
	if (action.payload.path === '') {
		return state;
	} else {
		let result = state;
		const keys = action.payload.path.split('/');
		for (const key of keys) {
			result = result[key];
			if (!result) {
				break;
			}
		}
		return result;
	}
}