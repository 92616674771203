import React from 'react';
import { Button } from '@mui/material';
import utils from '../../utils';
import Tooltip from '../Tooltip/Tooltip';

export default function ButtonBasic({
	label,
	icon,
	style,
	onClick,
	disabled,
	children,
	id,
	className,
	hideLabelOnLowRes,
	onClickSendProps,
	transparent,
	tooltip,
}) {
	const iconCross = icon === 'plus1' ? 'buttonBasic__icon-cross' : '';
	let finalClassName = `${className} buttonBasic`;
	finalClassName = style ? finalClassName = finalClassName + ' buttonBasic__' + style : finalClassName + ' buttonBasic__basic ';
	finalClassName = disabled ? finalClassName = finalClassName + ' buttonBasic__disabled' : finalClassName;
	finalClassName = hideLabelOnLowRes ? finalClassName = finalClassName + ' buttonBasic__hideLabel' : finalClassName;
	finalClassName = transparent ? finalClassName = finalClassName + ' buttonBasic__transparent' : finalClassName;

	return (
		<Tooltip placement="top" title={tooltip} >
			<Button id={id} onClick={(e) => onClickSendProps ? onClick(e) : onClick()} disabled={disabled} className={finalClassName}>
				{icon ? <span className={`buttonBasic__icon icon-${icon} buttonBasic__icon-${label ? 'margin' : ''} ${iconCross}`}></span> : null}
				{label ?
					<span className="buttonBasic__content">
						{utils.translate(label)}
						{children}
					</span> : null}
			</Button>
		</Tooltip>
	);
}