import { useSelector } from 'react-redux';

export default function dsGet(path) {
	// eslint-disable-next-line
	const value = useSelector(state => {
		let result = state;
		const keys = path?.split('/');
		for (const key of keys) {
			result = result[key];
			if (!result) {
				break;
			}
		}
		return result;
	});
	return value;
}