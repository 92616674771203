import utils from '../../utils';

export default function other(module, buttonName) {

	return () => {
		utils
			.post('other', {module: module, name: buttonName})
			.then((res) => {})
			.catch((error) => {
				console.error(error);
			});
	};
}
