export default function isDateInCurrentWeek(dateString) {
	const date = new Date(dateString);
	const currentDate = new Date();
	const currentDay = currentDate.getDay();
	const daysUntilMonday = currentDay === 0 ? 6 : currentDay - 1;
	currentDate.setHours(0, 0, 0, 0);
	date.setHours(0, 0, 0, 0);
	const mondayOfCurrentWeek = new Date(currentDate);
	mondayOfCurrentWeek.setDate(currentDate.getDate() - daysUntilMonday);
	const sundayOfCurrentWeek = new Date(mondayOfCurrentWeek);
	sundayOfCurrentWeek.setDate(mondayOfCurrentWeek.getDate() + 6);

	return date >= mondayOfCurrentWeek && date <= sundayOfCurrentWeek;
}