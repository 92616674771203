/**
 * @param {String} module - name of module to check rights
 * @param {Object} userData - user data from the redux store
 * @param {Number} rightLevel - level of requested right (0 - no access, 1 - readonly, 2 - full control)
 * @returns {boolean}
 */
export default function checkUserRights(module, userData, rightLevel = 0) {
	// System admin have access everywhere
	if (userData.sysadmin === 1) {
		return true;
	}
	// When right is 0 user have no access
	if (userData.rights[module] === 0) {
		return false;
	}
	// When right number is bigger or equal then requested rightLevel user has access
	if (userData.rights[module] >= rightLevel) {
		return true;
	}
	return false;
}
