import {default as MuiSwitch} from '@mui/material/Switch';
import React from 'react';

export default function Switch({onChange, checked, label}) {
	return (
		<div className="switchWrapper">
			<MuiSwitch
				onChange={(e) => onChange(e)}
				checked={checked}
				className="switch"
			/>
			<span className="switch__label">{label}</span>
		</div>
	);
}
