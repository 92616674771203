import utils from '..';

export default function downloadFile(module, recordId, name, fileType, sAction) {
	sAction.load();
	utils
		.postFile('downloadFile', {module, recordId}, true)
		.then((res) => {
			const fileBlob = new Blob([res]);
			const fileUrl = window.URL.createObjectURL(fileBlob);
			const link = document.createElement('a');
			link.href = fileUrl;
			link.download = `${name}.${fileType}`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(fileUrl);
			sAction.unload();
		})
		.catch((error) => {
			console.error('Error downloading file:', error);
			sAction.unload();
		});
}
