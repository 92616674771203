export default function setDetailChanges(state, action) {
	const { prefix, changes } = action.payload;

	if (prefix.includes('popups/')) {
	  const popupIndex = parseInt(prefix.split('/')[1], 10);
	  return {
			...state,
			popups: [
		  ...state.popups.slice(0, popupIndex),
		  {
					...state.popups[popupIndex],
					detail: {
			  ...state.popups[popupIndex].detail,
			  changes: {
							...state.popups[popupIndex].detail.changes,
							...changes,
			  },
					},
		  },
		  ...state.popups.slice(popupIndex + 1),
			],
	  };
	} else {
	  return {
			...state,
			view: {
		  		...state.view,
		  		detail: {
					...state.view.detail,
					changes: {
			  			...state.view.detail.changes,
			  			...changes,
					},
		  		},
			},
	  	};
	}
}