export default function initList(state, action) {
	if (action.payload.prefix !== 'view') {
		const popupIndex = parseInt(action.payload.prefix.split('/')[1], 10);
		if (!isNaN(popupIndex) && popupIndex >= 0 && popupIndex < state.popups.length) {
			const updatedPopups = [...state.popups];
			updatedPopups[popupIndex] = {
				...updatedPopups[popupIndex],
				list: {
					...state.list,
					...action.payload,
				},
			};

			const retValue =  {
				...state,
				popups: updatedPopups,
			};
			return retValue;
		}
	} else {
		return {...state, view: {
			...state.view,
			list: {
				...state.list,
				...action.payload,
			}
		}};
	}
}
