import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import utils from '../../../utils';

export default function CheckboxFilter({column, value, setFilter}) {

	return (
		<div  className="listViewHeader__filter-checkbox">
			<FormControl fullWidth>
				<InputLabel className="listViewHeader__filterInput-selectLabel">
					{utils.translate(column.label)}
				</InputLabel>
				<Select
					className="listViewHeader__filterInput"
					variant="standard"
					value={value}
					label={utils.translate(column.label)}
					onChange={(e) => setFilter(column.columnName, e.target.value)}
				>
					<MenuItem className="selectField__item" value={-1}>{utils.translate('LBL_ALL')}</MenuItem>
					<MenuItem className="selectField__item" value={0}>{utils.translate('LBL_NO')}</MenuItem>
					<MenuItem className="selectField__item" value={1}>{utils.translate('LBL_YES')}</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}
