import dsSet from './dsSet';
import initData from './initData';
import load from './load/load';
import unload from './load/unload';
import initDetail from './detail/initDetail';
import initList from './list/initList';
import changeLanguage from './language/changeLanguage';
import setToastSlice from './content/setToastSlice';
import destroyToastSlice from './content/destroyToastSlice';
import openPopup from './content/openPopup';
import closePopup from './content/closePopup';
import confirmPopup from './content/confirmPopup';
import contentLoad from './load/contentLoad';
import contentUnload from './load/contentUnload';
import setDetailChange from './detail/setDetailChange';
import setDetailChanges from './detail/setDetailChanges';
import errorPopup from './content/errorPopup';
import clearModuleData from './clearModuleData';
import setPredefinedFields from './detail/setPredefinedFields';
import initSubpanels from './detail/initSubpanels';

const actionCreators = {
	dsSet,
	initData,
	load,
	unload,
	initDetail,
	initList,
	changeLanguage,
	setToastSlice,
	destroyToastSlice,
	openPopup,
	closePopup,
	confirmPopup,
	contentLoad,
	contentUnload,
	setDetailChange,
	setDetailChanges,
	errorPopup,
	clearModuleData,
	setPredefinedFields,
	initSubpanels,
};

export default actionCreators;