import React, { useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {default as MuiTooltip} from '@mui/material/Tooltip';
import Tooltip from '../Tooltip/Tooltip';

export default function Phone({
	label,
	readonly,
	value,
	required,
	onChange,
	error,
	fieldName,
	onBlur,
	defaultCountry,
	info,
}) {
	const debugMode = localStorage.getItem('debugMode') ? true : false;
	const prevCountryCodeRef = useRef('');

	const handleChange = (phone, country) => {
		if (onChange) {
			const syntheticEvent = {
				target: {
					name: fieldName,
					value: phone,
				},
			};
			onChange(syntheticEvent);
		}

		if (country.dialCode !== prevCountryCodeRef.current) {
			prevCountryCodeRef.current = country.dialCode;
			if (onBlur) {
				const syntheticBlurEvent = {
					target: {
						name: fieldName,
						value: phone,
					},
				};
				onBlur(syntheticBlurEvent);
			}
		}
	};

	const handleBlur = (e) => {
		if (onBlur) {
			onBlur(e);
		}
	};

	return (
		<div className="inputFieldBox">
			{label && (
				<label className="detailField__label">
					{label}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon icon-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<div className="phoneBox">
				<PhoneInput
					country={defaultCountry || 'cz'}
					value={value}
					onChange={(phone, country) => handleChange(phone, country)}
					onBlur={(e) => handleBlur(e)}
					readonly={readonly}
				/>
			</div>
			{error != null ? (
				<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
					<span className="icon-warning inputFieldBox__error-icon"></span>
				</MuiTooltip>
			) : null}
		</div>
	);
}
