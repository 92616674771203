export default function initSubpanels(state, action) {
	const { prefix, subpanels } = action.payload;

	if (prefix === 'view') {
		return {
			...state,
			view: {
				...state.view,
				detail: {
					...state.view.detail,
					subpanels: subpanels,
				},
			},
		};
	} else if (prefix.startsWith('popups/')) {
		const popupIndex = parseInt(prefix.split('/')[1], 10);

		if (!isNaN(popupIndex) && popupIndex >= 0 && popupIndex < state.popups.length) {
			const updatedPopups = [...state.popups];
			updatedPopups[popupIndex] = {
				...updatedPopups[popupIndex],
				detail: {
					...updatedPopups[popupIndex].detail,
					subpanels: subpanels,
				},
			};

			return {
				...state,
				popups: updatedPopups,
			};
		}
	} else {
		// Split the prefix and create the path dynamically
		const keys = prefix.split('/');
		const lastKey = keys.pop();

		let nestedState = state;
		for (let key of keys) {
			if (!nestedState[key]) {
				nestedState[key] = {};
			}
			nestedState = nestedState[key];
		}

		nestedState[lastKey] = {
			...nestedState[lastKey],
			subpanels: subpanels,
		};

		return { ...state };
	}

	return state;
}