import React from 'react';
import ListViewRow from './ListViewRow';

export default function ListViewTable({
	viewData,
	popupSelectCallback,
	prefix,
	disableMultiple,
	rows,
	disabledRecords,
	hiddenRecords,
}) {

	const renderData = () => {
		return rows?.map((line) => {
			if (hiddenRecords?.includes(line?.id)) {
				return;
			}
			return <ListViewRow
				viewData={viewData}
				key={`${line.id}_${viewData?.module}`}
				rowData={line}
				popupSelectCallback={popupSelectCallback}
				prefix={prefix}
				disableMultiple={disableMultiple}
				disabledRecords={disabledRecords}
			/>;
		});
	};

	return (
		<tbody>
			{renderData()}
		</tbody>
	);
}
