export default function validatePassword(password, password2) {
	let errorMessages = {password: '', password2: ''};
	if (password === '') {
		errorMessages.password = 'Enter password';
	}
	if (password2 === '') {
		errorMessages.password2 = 'Enter confirmation password';
	}
	if (password !== password2) {
		errorMessages.password2 = 'Enter same passwords';
	}
	if (password.length < 6) {
		errorMessages.password = 'Password must be at least 6 characters';
	}
	return errorMessages;
}