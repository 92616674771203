import React, {useEffect} from 'react';
import utils from '../../utils';

export default function CalendarViewSwitch({setCalType, calType, calendarRef}) {
	useEffect(() => {
		setTimeout(() => {
			if (calType === 'day') {
				calendarRef.current.getApi().changeView('timeGridDay');
			} else if (calType === 'week') {
				calendarRef.current.getApi().changeView('timeGridWeek');
			} else {
				calendarRef.current.getApi().changeView('dayGridMonth');
			}
		});
	}, [calType]);

	return (
		<div className="calendarViewSwitch">
			<button className={`calendarViewSwitch__button ${calType === 'day' ? 'calendarViewSwitch__button-active' : ''}`} onClick={() => setCalType('day')}>
				{utils.translate('LBL_DAY')}
			</button>
			<button className={`calendarViewSwitch__button ${calType === 'week' ? 'calendarViewSwitch__button-active' : ''}`} onClick={() => setCalType('week')}>
				{utils.translate('LBL_WEEK')}
			</button>
			<button className={`calendarViewSwitch__button ${calType === 'month' ? 'calendarViewSwitch__button-active' : ''}`} onClick={() => setCalType('month')}>
				{utils.translate('LBL_MONTH')}
			</button>
		</div>
	);
}
