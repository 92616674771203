import React, {useState, useEffect} from 'react';
import { TextField } from '@mui/material';
import utils from '../../../utils';

export default function TextFilter({column, value, setFilter}) {
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<TextField
			className="listViewHeader__filterInput"
			label={utils.translate(column.label)}
			variant="standard"
			value={inputValue}
			onChange={(e) => setInputValue(e.target.value)}
			onBlur={() => setFilter(column.columnName, inputValue)}
		/>
	);
}
