import React from 'react';
import utils from '../../utils';
import {Link} from 'react-router-dom';

export default function NotFound() {
	return (
		<div className="login">
			<div className="login__container">
				<h4 className="login__headerLogo">
					<img className="login__logo" src="/images/logo2_dark.png" alt="App logo" />
					<br />
					<br />
					404
				</h4>
				<div className="login__label">Page not found</div>

				<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
					Back to Offerto app
				</Link>
			</div>
		</div>
	);
}
