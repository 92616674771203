import React, {useState} from 'react';
import Switch from '../FormElements/Switch';
import utils from '../../utils';
import useAction from '../../store/actions';

export default function NotificationsSettings({userData}) {
	const sAction = useAction();
	const [state, setState] = useState({
		systemNotifications: userData.systemNotifications,
		emailNotifications: userData.emailNotifications,
	});

	const setValue = (name, value) => {
		setState({...state, [name]: value});

		utils.post('setNotificationsSettings', {module: 'notification', data: {...state, [name]: value}})
			.then((res) => {
				if (res.data) {
					sAction.dsSet(`user/${name}`, value);
				}
			}).catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="notificationsSettings">
			<div className="notificationsSettings__item">
				<div>{utils.translate('LBL_SYSTEM_NOTIFICATION')}</div>
				<Switch
					onChange={(e) => setValue('systemNotifications', e.target.checked)}
					checked={state.systemNotifications}
				/>
			</div>
			<div className="notificationsSettings__item">
				<div>{utils.translate('LBL_EMAIL_NOTIFICATION')}</div>
				<Switch
					onChange={(e) => setValue('emailNotifications', e.target.checked)}
					checked={state.emailNotifications}
				/>
			</div>
		</div>
	);
}