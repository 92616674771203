import React, {useEffect, useState} from 'react';
import utils from '../../utils';
import useAction from '../../store/actions';
import {Tabs, Tab} from '@mui/material';
import DetailPanel from './DetailPanel/DetailPanel';
import AppError from '../../pages/Error/AppError';
import ActionButtons from '../ActionButtons/ActionButtons';
import Loader from '../Loader/Loader';

export default function Detail({userData, prefix, popupCallback, config, viewData}) {
	const [selectedTab, setSelectedTab] = useState(0);
	const [error, setError] = useState(false);
	const [recordId, setRecordId] = useState(null);
	const [load, setLoad] = useState(true);
	const sAction = useAction();

	// const viewData = sAction.dsGet(`${prefix}`);

	const translates = sAction.dsGet('config/translate/label');
	const isPopup = prefix.includes('popup');

	const initRecordId = isPopup ? viewData.recordId : utils.getRecordId();
	const initModuleName = isPopup ? viewData.module : utils.getModuleName();

	const renderTabs = () => {
		return viewData.detail?.tabs?.map((tab) => {
			return <Tab key={tab?.name} label={translates[tab?.label]} />;
		});
	};

	const renderPanels = (tab) => {
		if (tab?.panels) {
			return Object.keys(tab?.panels)?.map((key) => {
				const panel = tab?.panels[key];
				if (panel.name === 'subpanels' && recordId === 'newRecord') {
					return null;
				}
				return <DetailPanel
					prefix={prefix}
					userData={userData}
					viewData={viewData}
					key={panel.name}
					panelData={panel}
					fields={viewData.detail.fields}
					recordId={recordId}
					module={viewData.module}
				/>;
			});
		}
	};

	const handleChange = (e, tabIndex) => {
		setSelectedTab(tabIndex);
	};

	if (isPopup) {
		useEffect(() => {
			setRecordId(initRecordId);
		}, [viewData.recordId]);
	} else {
		useEffect(() => {
			setRecordId(initRecordId);
		}, [window.location.pathname]);
	}

	// New record
	// useEffect(() => {
	// 	setRecordId(initRecordId);
	// 	if (viewData.module === initModuleName && !initRecordId) {
	// 		getDetail();
	// 	}
	// 	return () => {
	// 		if (prefix === 'view') {
	// 			sAction.clearModuleData();
	// 		}
	// 	};
	// }, []);

	// Existing record
	useEffect(() => {
		if (viewData.module === initModuleName && recordId) {
			getDetail();
		}
	}, [viewData.module, recordId]);

	const getDetail = () => {
		if (!isPopup) {
			sAction.contentLoad();
		}
		const sendRecordId = initRecordId === 'newRecord' ? null : initRecordId;
		utils
			.post('getDetail', {id: sendRecordId, module: initModuleName})
			.then((res) => {
				setLoad(false);
				sAction.contentUnload();
				if (res.data) {
					sAction.initDetail(
						res.data.tabs,
						res.data.fields,
						res.data.buttons,
						res.data.timeline,
						res.data.lines,
						prefix);
				} else {
					setError(true);
				}
			})
			.catch((error) => {
				setLoad(false);
				sAction.contentUnload();
				console.error(error);
			});
	};

	const errorMessage = utils.translate('LBL_DETAIL_NOT_FOUND');
	const infoMessage = utils.translate('LBL_DETAIL_NOT_FOUND_MESSAGE');
	if (error) {
		return <AppError errorMessage={errorMessage} infoMessage={infoMessage} />;
	}

	return (
		<>
			{isPopup && load && !config?.load ?
				<div className="loadBox"><Loader /></div>
				:
				<><div className="actionBox">
					<ActionButtons load={load} popupCallback={popupCallback} userData={userData} prefix={prefix} viewData={viewData} buttons={viewData?.detail?.buttons} />
				</div>
				<div className="detail">
					<div className="detail__content">
						{viewData.detail?.tabs?.length > 1 ?
							<Tabs value={selectedTab} onChange={(e, tabIndex) => handleChange(e, tabIndex)}>
								{viewData?.detail?.tabs ? renderTabs() : null}
							</Tabs>
							: null}
						{viewData.detail?.tabs?.length > 0 &&
						renderPanels(viewData.detail.tabs[selectedTab])
						}
					</div>
				</div></>
			}
		</>
	);
}
