import React, { useState, useEffect } from 'react';
import { Select, FormControl, MenuItem, InputLabel, Checkbox } from '@mui/material';
import utils from '../../../utils';

export default function SelectFilter({ column, setFilter, value }) {
	const [values, setValues] = useState(value);
	const options = utils.getEnum(column.options);
	const debugMode = localStorage.getItem('debugMode') ? true : false;

	useEffect(() => {
		setValues(value);
	}, [value]);

	const addItemChecked = (item) => {
		const tempValues = [...values];
		if (values.includes(item)) {
			const index = tempValues.indexOf(item);
			if (index !== -1) {
				tempValues.splice(index, 1);
			}
			setValues(tempValues);
		} else {
			setValues([...tempValues, item]);
		}
	};

	const renderItems = () => {
		const optionsKeys = Object.keys(options);
		return optionsKeys.map((key) => {
			const selected = values.includes(key);
			return (
				<MenuItem
					onClick={() => addItemChecked(key)}
					className="selectField__item"
					key={key}
					value={key}
				>
					<Checkbox
						className={`${!selected ? 'selectField__item-inactive' : 'selectField__item-active'}`}
						checked={selected}
					/>
					<div>{debugMode ? `${options[key]} [${key}]` : options[key]}</div>
				</MenuItem>
			);
		});
	};

	return (
		<FormControl fullWidth>
			<InputLabel className="listViewHeader__filterInput-selectLabel" variant="standard">
				{utils.translate(column.label)}
			</InputLabel>
			<Select
				className="listViewHeader__filterInput listViewHeader__filterInput-select"
				variant="standard"
				multiple={true}
				value={values}
				onClose={() => setFilter(column.columnName, values)}
				renderValue={(selected) => {
					const trVal = selected.map((value) => options[value]);
					return `${trVal[0] ? trVal[0] + ',' : ''}${trVal[1] ? trVal[1] + ',...' : ''}`;
				}}
			>
				{renderItems()}
			</Select>
		</FormControl>
	);
}