import React from 'react';

export default function AppErrorBackend({message}) {
	return (
		<div className="appErrorBackend">
			<span>OOPS! Server není dosutpný prosím nahlašte chybu administrátorovi.</span>
			<a href="">Zpět do aplikace</a>
			<div className="appErrorBackend__message">Message: <span>{message}</span></div>
		</div>
	);
}
