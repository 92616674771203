import utils from '../../utils';
import useAction from '../../store/actions';
import toast from 'react-hot-toast';

export default function createOrder(module, recordId, prefix, saveDetail) {
	const sAction = useAction();

	const labelCreatingOrder = utils.translate('LBL_CREATING_ORDER');
	const orderCreated = utils.translate('LBL_ORDER_SUCCESSFULLY_CREATED');
	const labelError = utils.translate('LBL_ERROR');

	return async () => {
		sAction.confirmPopup('LBL_CREATE_ORDER_CONFIRM', () => {
			const resultPromise = utils.post('createOrder', {module: module, recordId: recordId});
			toast.promise(resultPromise, {
				loading: labelCreatingOrder,
				success: orderCreated,
				error: labelError,
			});

			if (resultPromise.data === 0) {
				toast.error(labelError);
			} else {
				sAction.dsSet(`${prefix}/detail/fields/status/value`, 'approved');
				saveDetail({status: 'approved'});
			}
		});
	};
}
