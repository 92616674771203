import utils from '../../utils';
import useAction from '../../store/actions';
import { useNavigate } from 'react-router-dom';

export default function deleteRecord(module, recordId, isPopup, listRecords, selectedRecords, popupCallback) {
	const sAction = useAction();
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	const confirmedDelete = () => {
		sAction.contentLoad();
		let records = [];
		let recordsArray = [];
		let arrayWithoutRecords = [];
		if (!isPopup) {
			records = listRecords;
			recordsArray = selectedRecords;
			arrayWithoutRecords = records.filter((record) => !recordsArray.includes(record.id));
		}
		// Delete on detail
		if (recordsArray?.length === 0 || !recordsArray) {
			recordsArray.push(recordId);
		}
		utils
			.post('deleteRecords', {module, records: recordsArray})
			.then((res) => {
				if (res.data == 0) {
					sAction.errorPopup(res.dataResult);
				} else {
					if (!isPopup) {
						navigate(`/${urlPrefix}/list/${module}`);
						sAction.dsSet('view/list/data', arrayWithoutRecords);
					} else {
						popupCallback();
						sAction.closePopup();
					}
				}
				sAction.contentUnload();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return () => {
		sAction.confirmPopup('LBL_CONFIRM_DELETE_RECORDS', () => {
			confirmedDelete();
		});
	};
}
