import React, {useState, useEffect, useRef} from 'react';
import {default as MuiTooltip} from '@mui/material/Tooltip';
import utils from '../../utils';

export default function Tooltip({
	children,
	placement = 'top',
	title,
	style,
}) {
	const [open, setOpen] = useState(false);
	const tooltipRef = useRef();

	const translate = utils.translate(title);

	const handleClickOutside = (event) => {
		if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open]);

	if (title) {
		return (
			<MuiTooltip
				arrow
				placement={placement}
				title={<div style={style} className="tooltip">{translate}</div>}
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				ref={tooltipRef}
			>
				{children}
			</MuiTooltip>
		);
	} else {
		return (
			<>
				{children}
			</>
		);
	}
}
