import toast from 'react-hot-toast';

export default function copyToClipboard(text) {
	navigator.clipboard.writeText(text)
		.then(() => {
			toast.success('Copied');
		})
		.catch((err) => {
			toast.error('Error');
			console.error(err);
		});
}