import useAction from '../../store/actions';
import { useNavigate } from 'react-router-dom';
import utils from '../../utils';

export default function importList(module) {
	const sAction = useAction();
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	return () => {
		sAction.dsSet('view/module', module);
		navigate(`/${urlPrefix}/import/${module}`);
	};
}
