export default {
	nav: {
		product: 'Produkt',
		about: 'O nás',
		partners: 'Partneři',
		offerto: 'Chci Offerto!'
	},
	main: {
		header: 'Spolehlivá data v reálném čase',
		button: 'Začněte',
	},
	about: {
		header: 'Data, kterým můžete věřit',
		firstSectionHeader: '1. Databáze produktů',
		firstSectionText: 'Naše jedinečné řešení konfigurace produktů Vám umožní že už nikdy nebudete muset žádné informace hledat nebo počítat ručně. S offertem si můžete nastavit všechny možné varianty Vašeho produktu s jeho ptřebnými doplňky které tak naceníte v nabídce jedním kliknutím!',
		secondSectionHeader: '2. Tvorba cenových nabídek',
		secondSectionText: 'Díky Offertu získáte automatizovaný nástroj pro tvorbu cenových nabídek jak pro Vaše obchodníky, tak i zákazníky.',
		thirdSectionHeader: '3. Tvorba objednávek',
		thirdSectionText: 'Objednávejte jedním kliknutím s přímým napojením do ERP systému jako je Pohoda, Helios, SAP a další.',
		fourthSectionHeader: '4. Databáze firem',
		fourthSectionText: 'Mějte všechny své klienty, nabídky i objednávky hezky uspořádané k firmě se kterou obchodujete.',
		fifthSectionHeader: '5. Systémové notifikace',
		fifthSectionText: 'Offerto vám vždy dá vědět každou důležitou informaci do systému i do Vašeho emailu. Už nikdy vám neuteče nic důlžitého!',
		sixthSectionHeader: '6. Reporty',
		sixthSectionText: 'Máme přehledné reporty díky kterým můžete mít dokonalý přehled o souhrnu všech dat které potřebujete. A ano! Děláme i reporty na míru.',
	},
	solution: {
		header: 'Rychlejší řešení obchodních případů',
		text: 'Offerto Vám i Vašim zákazníkům pomůže rychle vyřešit cenu Vašeho zboží, dostupnost a objednání s přímým napojením do ERP systému. Vyzkoušejte, jak jednoduché to je!',
		button: 'Chci vyzkoušet Offerto'
	},
	story: {
		header: 'O nás',
		sections: {
			story: {
				header: 'Příběh',
				text: 'Offerto vzniklo z nápadu dvou našich obchodníků. Naším cílem je, firmám pomoci zrychlit a zjednodušit jejich obchodní procesy s různými montážními trasami jakéhokoli druhu. Po dlouhé práci našich zkušených programátorů vznikl software který dokáže nejen to, ale i mnohem více a víme, že příběh Offerta ještě nekončí a mnoho dalších skvělých věcí se pro naše zákazníky chystá.'
			},
			vision: {
				header: 'Vize',
				text: 'Naší vizí je plně automatizovat a zrychli obchodní procesy. S námi už Vaši obchodníci nebudou muset používat excel s kalkulačkou v ruce. Našim cílem je aby se obchodníci mohli věnovat důležitějším věcem než je hledání cen v kalatogu. Při spolupráci s našimi klienty hledíme především na funkčnost našeho řešení ale také na jeho vizuální stránku aby tvořilo příjemné prostředí do kterého se Vy i Vaši klienti budou rádi vracet.'
			},
			technology: {
				header: 'Technologie',
				text: 'Offerto! Inovativní software, který se plně přizpůsobí Vaší společnosti a zajistí rychlejší komunikaci a výměnu dat mezi kolegy a zákazníky.'
			}
		}
	},
	reference: {
		header: 'Produced by',
		companyName: 'Innovasoft'
	},
	contact: {
		header: 'Řekněte si o ukázku',
		subheader: 'Sjednejte si ukázku s naším specialistou',
		form: {
			name: 'Jméno',
			lastName: 'Příjmení',
			email: 'Email',
			phone: 'Telefon',
			company: 'Společnost',
			position: 'Pozice',
			button: 'Odeslat'
		},
		messages: {
			formError: 'Nepodařilo se odeslat email pro kontaktní osobu. Prosím kontaktujte nás na emailu info@innovasoft.cz děkujeme.',
			formSuccess: 'Odeslání formuláře bylo úspěšné',
			requiredFieldsAlert: 'Prosím vyplňtě všechna povinná pole!',
			validEmailAlert: 'Prosím vyplňtě platný email!',
			recaptchaAlert: 'Prosím ověřtě že nejste robot!'
		}
	},
	footer: {
		email: 'E-mail:',
		phone: 'Telefon:',
		copyright: 'Innovasoft',
		social: 'Sociální sítě'
	}
};
