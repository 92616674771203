import React, { useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import { enUS, cs, sk } from 'date-fns/locale';
import useAction from '../../store/actions';
import Tooltip from '../Tooltip/Tooltip';
import {default as MuiTooltip} from '@mui/material/Tooltip';

export default function DateField({
	value,
	onChange,
	fieldName,
	label,
	disableFuture,
	disablePast,
	error,
	required,
	defaultValue,
	info,
}) {
	const sAction = useAction();
	const language = sAction.dsGet('user/language');
	const debugMode = localStorage.getItem('debugMode') ? true : false;

	const handleChange = (date) => {
		let formattedDate;

		if (date instanceof Date && !isNaN(date)) {
			formattedDate = format(date, 'yyyy-MM-dd');
		} else {
		// Handle invalid or empty input
			formattedDate = '0000-00-00';
		}

		onChange({
			target: {
				name: fieldName,
				value: formattedDate,
			},
		});
	};

	const localeMap = {
		en: enUS,
		cz: cs,
		sk: sk,
	};

	const locale = localeMap[language] || enUS;

	if (value === '0000-00-00') {
		value = null;
	}

	return (
		<LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
			<div className={`dateField ${error ? 'dateField__error' : 'dateField__outline'}`}>
				{label && (
					<label className="detailField__label">
						{label}
						{debugMode ? <span className="debugName"> {fieldName}</span> : null}
						{info ?
							<Tooltip placement="top" title={info}>
								<span className="fieldIcon icon-info"/>
							</Tooltip> : null}
						{required ? <span className="detailField__required">*</span> : null}
					</label>
				)}
				<DesktopDatePicker
					className="dateField__datePicker"
					value={isValid(parseISO(value)) ? parseISO(value) : parseISO(defaultValue) || null}
					format="dd.MM.yyyy"
					onChange={(e) => handleChange(e)}
					name={fieldName}
					closeOnSelect={true}
					disableFuture={disableFuture}
					disablePast={disablePast}
					displayWeekNumber
				/>
				{error ? (
					<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
						<span className="dateField__tooltip icon-warning inputFieldBox__error-icon"></span>
					</MuiTooltip>
				) : null}
			</div>
		</LocalizationProvider>
	);
}