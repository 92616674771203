import useAction from '../../store/actions';

export default function approveQuote(prefix, saveDetail) {
	const sAction = useAction();

	return () => {
		sAction.confirmPopup('LBL_CONFIRM_APPROVED', () => {
			sAction.dsSet(`${prefix}/detail/fields/status/value`, 'approved');
			saveDetail({status: 'approved'});
		});
	};
}
