/* eslint-disable no-case-declarations */
/* eslint-disable no-console */
import initialState from '../states';
import changeLanguage from './reducerActions/changeLanguage';
import initData from './reducerActions/initData';
import dsSet from './reducerActions/dsSet';
import dsGet from './reducerActions/dsGet';
import initDetail from './reducerActions/initDetail';
import initList from './reducerActions/initList';
import setToastSlice from './reducerActions/setToastSlice';
import destroyToastSlice from './reducerActions/destroyToastSlice';
import openPopup from './reducerActions/openPopup';
import closePopup from './reducerActions/closePopup';
import confirmPopup from './reducerActions/confirmPopup';
import errorPopup from './reducerActions/errorPopup';
import setDetailChange from './reducerActions/setDetailChange';
import clearModuleData from './reducerActions/clearModuleData';
import setPredefinedFields from './reducerActions/setPredefinedFields';
import initSubpanels from './reducerActions/initSubpanels';
import setDetailChanges from './reducerActions/setDetailChanges';

export default function reducer (state = initialState, action){
	let returnedState = state;

	switch (action.type) {
	case 'LOAD':
		returnedState = {...state, config : {
			...state.config,
			load: true,
		}};
		break;
	case 'UNLOAD':
		returnedState = {...state, config : {
			...state.config,
			load: false,
		}};
		break;
	case 'CONTENT_LOAD':
		returnedState = {...state, config : {
			...state.config,
			contentLoad: true,
		}};
		break;
	case 'CONTENT_UNLOAD':
		returnedState = {...state, config : {
			...state.config,
			contentLoad: false,
		}};
		break;
	case 'INIT_DETAIL':
		returnedState = initDetail(state, action);
		break;
	case 'INIT_LIST':
		returnedState = initList(state, action);
		break;
	case 'INIT_DATA':
		returnedState = initData(state, action);
		break;
	case 'SET_STORE_VALUE':
		returnedState = dsSet(state, action);
		break;
	case 'GET_STORE_VALUE':
		returnedState = dsGet(state, action);
		break;
	case 'CHANGE_LANGUAGE':
		returnedState = changeLanguage(state, action);
		break;
	case 'SET_TOAST_SLICE':
		returnedState = setToastSlice(state, action);
		break;
	case 'DESTROY_TOAST_SLICE':
		returnedState = destroyToastSlice(state, action);
		break;
	case 'OPEN_POPUP':
		returnedState = openPopup(state, action);
		break;
	case 'CLOSE_POPUP':
		returnedState = closePopup(state);
		break;
	case 'CONFIRM_POPUP':
		returnedState = confirmPopup(state, action);
		break;
	case 'ERROR_POPUP':
		returnedState = errorPopup(state, action);
		break;
	case 'SET_DETAIL_CHANGE':
		returnedState = setDetailChange(state, action);
		break;
	case 'SET_DETAIL_CHANGES':
		returnedState = setDetailChanges(state, action);
		break;
	case 'CLEAR_MODULE_DATA':
		returnedState = clearModuleData(state);
		break;
	case 'SET_PREDEFINED_FIELDS':
		returnedState = setPredefinedFields(state, action);
		break;
	case 'INIT_SUBPANELS':
		returnedState = initSubpanels(state, action);
		break;
	default:
		returnedState = state;
	}

	// eslint-disable-next-line no-undef
	const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
	if (appEnvironment === 'dev') {
		console.log(returnedState, action.type);
	}
	return returnedState;
}
