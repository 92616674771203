import React from 'react';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import { enUS, cs, sk } from 'date-fns/locale';
import useAction from '../../../store/actions';

export default function DatetimeFilter({column, value, setFilter}) {
	const sAction = useAction();
	const language = sAction.dsGet('user/language');
	const localeMap = {
		en: enUS,
		cz: cs,
		sk: sk,
	};

	const handleChange = (name, date) => {
		let formattedDate;

		if (date instanceof Date && !isNaN(date)) {
			formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
		} else {
			return;
		}

		if (name === 'from') {
			setFilter(column.columnName, {from: formattedDate, to: value?.to});
		} else {
			setFilter(column.columnName, {to: formattedDate, from: value?.from});
		}
	};

	const handleClear = (name) => {
		if (name === 'from') {
			setFilter(column.columnName, {from: '', to: value?.to});
		} else {
			setFilter(column.columnName, {to: '', from: value?.from});
		}
	};

	const locale = localeMap[language] || enUS;

	return (
		<LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
			<div className="datetimeFilter">
				<div className="datetimeFilter__box">
					<span>Od:</span>
					<DesktopDateTimePicker
						views={['year', 'month', 'day', 'hours', 'minutes']}
						ampm={false}
						slotProps={{ textField: { variant: 'standard', } }}
						className="datetimeFilter__date"
						onChange={(date) => handleChange('from', date)}
						value={isValid(parseISO(value?.from)) ? parseISO(value?.from) : null}
					/>
					<span onClick={() => handleClear('from')} className="icon-cross datetimeFilter__clear"></span>
				</div>
				<div className="datetimeFilter__box dateBox">
					<span>Do:</span>
					<DesktopDateTimePicker
						views={['year', 'month', 'day', 'hours', 'minutes']}
						ampm={false}
						slotProps={{ textField: { variant: 'standard', } }}
						className="datetimeFilter__date"
						onChange={(date) => handleChange('to', date)}
						value={isValid(parseISO(value?.to)) ? parseISO(value?.to) : null}
					/>
					<span onClick={() => handleClear('to')} className="icon-cross datetimeFilter__clear"></span>
				</div>
			</div>
		</LocalizationProvider>
	);
}