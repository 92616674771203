import React, { useState, useEffect, Suspense } from 'react';
import Loader from '../../Loader/Loader';

export default function DetailPanel({ panelData, fields, viewData, userData, prefix, recordId, module }) {
	const [Component, setComponent] = useState(null);

	useEffect(() => {
		if (!prefix.includes('popup') || panelData?.component === 'DetailForm') {
			import(`../DetailComponents/${panelData?.component}/${panelData?.component}`).then((module) => {
				setComponent(() => module.default);
			});
		}
	}, [panelData?.component, prefix]);

	return (
		<Suspense fallback={<Loader />}>
			{Component ? (
				<Component
					prefix={prefix}
					userData={userData}
					panelData={panelData}
					fields={fields}
					viewData={viewData}
					recordId={recordId}
					module={module}
				/>
			) : null}
		</Suspense>
	);
}