import * as l from './exportConfig';

const utils = {};
const listFieldsArrName = Object.keys(l);
const listFieldsArr = listFieldsArrName.map((k) => l[k]);
listFieldsArr.forEach(function(f, k) {
	utils[listFieldsArrName[k]] = f;
});

export default utils;
