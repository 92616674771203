import { useNavigate } from 'react-router-dom';
import utils from '../../utils';

export default function cancelDetail(module) {
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	return () => {
		navigate(`/${urlPrefix}/list/${module}`);
	};
}
