import React, {useState, useEffect} from 'react';
import useAction from '../../store/actions';
import Tooltip from '../Tooltip/Tooltip';

export default function Image({
	label,
	readonly,
	value,
	placeholder,
	fieldName,
	onChange,
	module,
	askDelete,
	recordId,
	allowDelete,
	allowUpload,
	detail,
	prefix,
	handleDelete,
	required,
	info,
}) {
	const sAction = useAction();
	const [image, setImage] = useState(null);
	const [imageValue, setImageValue] = useState(null);
	const debugMode = localStorage.getItem('debugMode') ? true : false;

	useEffect(() => {
		setImageValue(value);
	}, [value]);

	const handleChange = (e) => {
		const reader = new FileReader();
		const file = e.target.files[0];
		if (file) {
			reader.onloadend = () => {
				setImage(reader.result);
			};
			reader.readAsDataURL(file);
			onChange(e);
		}
	};

	const openImagePopup = () => {
		sAction.openPopup('ImagePopup', '', true, {module, recordId, image});
	};

	const deleteImage = () => {
		if (askDelete) {
			sAction.confirmPopup('LBL_CONFIRM_DELETE_IMAGE', () => {
				setImage(null);
				handleDelete();
			});
		} else {
			setImage(null);
			handleDelete();
		}
	};

	const displayValue = image || imageValue;

	return (
		<div className="inputFieldBox inputImageBox">
			{label && (
				<label className="detailField__label">
					{label}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon icon-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<div className="inputImage">
				<input
					placeholder={placeholder}
					className={`${imageValue ? 'inputImage__selected' : ''} inputImage__input inputField ${readonly ? 'inputField__readonly' : ''}`}
					onChange={(e) => handleChange(e)}
					type={'file'}
					id={fieldName}
					accept="image/png, image/gif, image/jpeg"
				/>
				<div className="inputImage__actions">
					{allowUpload ? <div className="icon-upload inputImage__upload" onClick={() => {!readonly ? document.getElementById(fieldName)?.click() : null;}}></div> : null}
					{allowDelete ? imageValue ? <div className="icon-trash-2 inputImage__delete" onClick={() => {!readonly ? deleteImage() : null;}}></div> : null : null}
				</div>
				{displayValue ?
					<div className="inputImage__imageBox" onClick={openImagePopup}><img src={displayValue} className={`${detail ? 'inputImage__imageDetail' : 'inputImage__image'}`} /></div>
					:
					<div className="icon-image inputImage__icon"></div>
				}
			</div>
		</div>
	);
}
