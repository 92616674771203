export default function formatPhoneNumber(number) {
	const result =  number?.replace(/(\d{1,3})(\d{1,3})?(\d{1,3})?/, (_, p1, p2, p3) => {
		let result = '';
		if (p1) result += p1;
		if (p2) result += ` ${p2}`;
		if (p3) result += ` ${p3}`;
		return result;
	});

	return result;
}