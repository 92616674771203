import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import actionCreators from './actionCreators';
import dsGet from './dsGet';

const otherActions = {
	dsGet,
};

export default function useAction() {
	// eslint-disable-next-line
	const dispatch = useDispatch();
	return {...bindActionCreators(actionCreators, dispatch), ...otherActions};
}
