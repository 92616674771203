import React from 'react';

export default function ListViewTextHtml({value, className, style}) {
	return (
		<td
			dangerouslySetInnerHTML={{__html: value}}
			className={`${className} listViewNumber`}
			style={style}
		/>
	);
}
