import useAction from '../../store/actions';
import utils from '../../utils';
import { useNavigate } from 'react-router-dom';

export default function createNewEvent(module, prefix, detailFields) {
	const sAction = useAction();
	const navigate = useNavigate();
	const urlPrefix = utils.getUrlPrefix();

	return () => {
		sAction.closePopup();
		const setFields = {};
		Object.keys(detailFields).forEach((fieldName) => {
			const fieldData = detailFields[fieldName];
			if (fieldData.value) {
				setFields[fieldName] = fieldData.value;
			}
			setFields['status'] = 'active';
			sAction.setPredefinedFields(setFields);
		});
		if (prefix === 'view') {
			navigate(`/${urlPrefix}/detail/${module}`);
		} else {
			sAction.openPopup('DetailFormPopup', 'LBL_EVENT', false, {module: 'event', recordId: 'newRecord'}, () => {
				sAction.closePopup();
				sAction.dsSet('view/calendar/reload', true);
			});
		}
	};
}
