import useAction from '../../store/actions';
import utils from '../../utils';
import toast from 'react-hot-toast';

export default function restorePassword(module, recordId) {
	const sAction = useAction();

	const restoreLabel = utils.translate('LBL_RESTORE_PASSWORD_SUCCESS');

	return () => {
		sAction.confirmPopup('LBL_RESTORE_PASSWORD_CONFIRM', () => {
			sAction.contentLoad();
			utils
				.post('restorePassword', {recordId, module})
				.then((res) => {
					if (res.data == 0) {
						sAction.errorPopup(res.dataResult);
					} else {
						toast.success(restoreLabel);
					}
					sAction.contentUnload();
				})
				.catch((error) => {
					console.error(error);
					sAction.contentUnload();
				});
		});
	};
}
