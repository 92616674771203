import React, {useState} from 'react';
import utils from '../../utils';
import Tooltip from '../Tooltip/Tooltip';
import useAction from '../../store/actions';

export default function FileField({
	value,
	fieldName,
	required,
	readonly,
	label,
	onChange,
	acceptedFiles,
	module,
	recordId,
	recordName,
	canDownload,
	info,
}) {
	const [filename, setFilename] = useState(value?.name || null);
	const debugMode = localStorage.getItem('debugMode') ? true : false;
	const sAction = useAction();

	const handleChange = (e) => {
		const newFile = e.target?.files[0];
		setFilename(newFile?.name);
		onChange(e);
	};

	const chooseFileLabel = utils.translate('LBL_CHOOSE_FILE');
	const acceptedFilesDotted = acceptedFiles.map(type => `.${type}`);

	let fileType = null;

	if (value) {
		fileType = value?.name ? value?.name?.split('.')?.slice(-1)?.[0] : value?.split('.')?.slice(-1)?.[0];
	}

	const translatedLabel = utils.translate(label);

	const download = () => {
		utils.downloadFile(module, recordId, recordName, fileType, sAction);
	};

	return (
		<div className="inputFieldBox fileField">
			{label && (
				<label className="detailField__label">
					{translatedLabel}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon icon-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<div className="fileField__box">
				{value ? <span className="fileField__file" onClick={value && canDownload ? () => download() : null}>{value?.name ? value?.name : value}</span> : null}
				{!readonly ?
					<input
						multiple
						id={fieldName}
						accept={acceptedFilesDotted}
						onChange={handleChange}
						className="fileField__input"
						name={fieldName}
						type="file" />
					: null}
				<label className={`fileField__label ${!value ? 'noValue' : ''} `} htmlFor={fieldName}>
					{!value ? <span>{filename ? filename : chooseFileLabel}</span> : null}
					{/* {value ? <span className="fileField__icon fileField__icon-delete icon-delete"/> : null} */}
					<span className="fileField__icon icon-upload"/>
				</label>
			</div>
		</div>
	);
}