import utils from '../../../utils';

export default function clearModuleData(state) {
	return { ...state, view: {
		...state.view,
		module: utils.getModuleName(),
		recordId: utils.getRecordId(),
		list: {
			...state.view.list,
			data: [],
			header: [],
			buttons: {},
			selected: [],
		},
		detail: {
			fields: {},
			tabs:  [],
			changes: {},
			buttons: {},
			subpanels: [],
			linesData: {
				def: [],
				lines: [],
			},
		},
	}
	};
}