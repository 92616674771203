import React from 'react';

export default function AppLoader({transparent}) {
	return (
		<>
			{!transparent ? <div className="appLoader__overlay"></div> : null}
			<div className="appLoader"></div>
		</>
	);
}
