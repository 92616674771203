import utils from '../../utils';

export default function detailChanges(module, recordId) {
	return () => {
		utils.post('logAsUser', {userId: recordId, module})
			.then((res) => {
				if (res.data != 0) {
					window.location.reload();
				}
			}).catch((error) => {
				console.error(error);
			});
	};
}
