import React, {useState} from 'react';
import utils from '../../utils';
import {TextField, Button} from '@mui/material';
import {Link} from 'react-router-dom';

export default function Forget() {
	const [email, setEmail] = useState('');
	const [usrID, setUsrID] = useState(0);
	const [error, setError] = useState({email: ''});

	const handleSubmit = (e) => {
		e.preventDefault();

		let validEmail = utils.validateEmail(email);

		let localError = {email: ''};

		if (!validEmail) {
			localError.email = 'Invalid email';
		}

		if (localError.email !== '') {
			return setError(localError);
		}

		setError({email: ''});

		utils
			.post('forget', {email}, false)
			.then((res) => {
				if (res['id'] > 0) {
					setUsrID(res['id']);
				} else {
					localError.email = res['resultTxt'];
					setError(localError);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="login">
			<div className="login__container">
				{usrID === 0 ? (
					<>
						<div className="login__label">Enter your email to restore password</div>
						<form className="login__form" onSubmit={handleSubmit} id="forgetForm">
							<div className="login__inputBox">
								<TextField className={`login__form-input ${error.email ? 'login__form-input--error' : ''}`} label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} value={email} helperText={error.email} error={!!error.eamil} />
							</div>
							<Button className="login__form-button" variant="contained" type="submit">
								Confirm
							</Button>
						</form>
						<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
							Back to Login
						</Link>
					</>
				) : (
					<>
						<div className="login__label">To your email was sent a link to reset your password. Please check your email inbox.</div>

						<Link to={`../${utils.getUrlPrefix()}/login`} className="login__link">
							Back to Login
						</Link>
					</>
				)}
			</div>
		</div>
	);
}
