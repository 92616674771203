import React from 'react';
import Select from '../../FormElements/Select';
import utils from '../../../utils';
import Pohoda from './ExternalSystemSettings/Pohoda';

export default function ExternalSystems({setValue, state}) {
	const options = utils.getEnum('externalSystem');

	const renderSettings = () => {
		switch (state.externalSystem) {
		case 'pohoda':
			return <Pohoda setValue={setValue} state={state} />;
		}
	};

	const handleChange = (e) => {
		setValue('externalSystem', e.target.value);
	};

	return (
		<>
			<div className="config__form-externalSystem">
				<Select
					onChange={(e) => handleChange(e)}
					value={state.externalSystem}
					label={utils.translate('LBL_SELECT_EXTERNAL_SYSTEM')}
					fieldName="externalSystem"
					options={options}
				/>
			</div>
			{renderSettings()}
		</>
	);
}
