export default function initData (state, action) {
	return { ...state, user: {
		...state.user,
		...action.payload.usrInfo,
	}, config: {
		...state.config,
		currencies: {
			...state.config.currencies,
			...action.payload.currencies,
		},
		systemSettings: {
			...state.config.systemSettings,
			...action.payload.systemSettings,
		},
		translate: {
			...state.config.translate,
			label: action.payload.translate.label,
			enum: action.payload.translate.enum,
		},
		leftMenu: action.payload.leftMenu,
	}, view: {
		...state.view,
		module: action.payload.module,
		recordId: action.payload.recordId,
	}
	};
}