import React from 'react';
import Switch from '../../FormElements/Switch';
import TextArea from '../../FormElements/TextArea';
import utils from '../../../utils';
import Select from '../../FormElements/Select';

export default function Print({setValue, state}) {
	const colors = window.colorPalette;
	const printVariants = utils.getEnum('quotePrintVariant');

	return (
		<div className="config__form">
			<div style={{borderBottom: `2px solid ${colors[0]}`}} className="config__form-section">
				<span className="icon-quote"/>
				{utils.translate('LBL_PRINT_QUOTES_ORDERS_SETTINGS')}
			</div>
			<Select
				onChange={(e) => setValue('quotePrintVariant', e.target.value)}
				value={state.quotePrintVariant}
				label={utils.translate('LBL_QUOTE_PRINT_VARIANT')}
				fieldName="quotePrintVariant"
				options={printVariants}
			/>
			<Switch
				onChange={(e) => setValue('printRounded', e.target.checked ? 1 : 0)}
				checked={!!state.printRounded}
				label={utils.translate('LBL_PRINT_ROUNDED')}
			/>
			<div style={{borderBottom: `2px solid ${colors[1]}`}} className="config__form-section">
				<span className="icon-quote"/>
				{utils.translate('LBL_TERMS_AND_CONDITIONS_SHORT')}
			</div>
			<TextArea
				label={utils.translate('LBL_TERMS_AND_CONDITIONS')}
				value={state.termsAndConditions}
				onBlur={(e) => setValue('termsAndConditions', e.target.value)}
				minRows={20}
				className="config__termsAndConditions"
			 />
		</div>
	);
}
